import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import type { ProductJson } from "~/jsonSchemas";
import { useMainData } from "~/hooks";
import type { Product } from "~/schemas";
import {
  NO_VARIATION,
  getOldPriceForVariation,
  getPriceForVariation,
} from "~/store";

interface ProductPriceProps {
  product: ProductJson | Product;
  variationId?: string;
}

export default function ProductPrice({
  product,
  variationId,
}: ProductPriceProps) {
  const { settings } = useMainData();
  const { currency } = product;

  const old = useMemo(() => {
    if (variationId) {
      return getOldPriceForVariation(product, variationId || NO_VARIATION);
    }

    // Don't show discounts when showing price ranges.
    return undefined;
  }, [variationId, product]);

  const current = useMemo(() => {
    if (variationId) {
      return getPriceForVariation(product, variationId || NO_VARIATION);
    }

    if (!product.variations) {
      return 0;
    }

    const minPrice = Math.min(
      ...(product
        .variations!.map((v) => v.currentPrice)
        .filter((p) => !!p) as number[])
    );
    const maxPrice = Math.max(
      ...(product
        .variations!.map((v) => v.currentPrice)
        .filter((p) => !!p) as number[])
    );

    if (minPrice === maxPrice) {
      return minPrice;
    }

    return `${minPrice} - ${maxPrice}`;
  }, [variationId, product]);

  return (
    <Box sx={{height: '4em' }}>
      {!!old && (
        <Typography component="span" variant="h5">
          <s>
            {old}
          </s>
          <br />
        </Typography>
      )}

      <Typography component="span" variant="h5" color={t => t.palette.primary.main}>
        {current} {currency || settings.defaultCurrency}
      </Typography>
    </Box>
  );
}
