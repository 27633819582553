import type { Product } from "~/schemas";
import DtwImage from "./DtwImage";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useMainData, useTextColorForSecondaryBg } from "~/hooks";
import type { ProductJson } from "~/jsonSchemas";
import { NO_VARIATION, useCartStore } from "~/store";
import CartCounter from "./CartCounter";
import { ClientOnly } from "remix-utils/client-only";
import { Link } from "@remix-run/react";
import type { MouseEvent } from "react";
import { useEffect, useMemo, useState } from "react";
import ProductPrice from "./ProductPrice";
import { ShoppingCartOutlined } from "@mui/icons-material";

interface ProductCardProps {
  product: Product | ProductJson;
  className?: string;
  disableInteraction?: boolean;
}

function ProductCard({
  product,
  className,
  disableInteraction,
}: ProductCardProps) {
  const main = useMainData();
  const { categories } = main;
  const { variations } = product;
  const secondaryBgTextColor = useTextColorForSecondaryBg();

  const [variationId, setVariationId] = useState<string | undefined>();

  const [qty, increase] = useCartStore((s) => [
    s.cart[product.id],
    s.incrementCartQty,
  ]);
  const variationInCart =
    variationId && qty ? qty[variationId] !== undefined : false;

  useEffect(() => {
    // Auto-select the variation the user has in cart, if any.
    if (!variationId && qty && Object.keys(qty).length > 0) {
      setVariationId(Object.keys(qty)[0]);
    } else if (!variations || variations.length === 0) {
      // The product does not have any variations
      setVariationId(NO_VARIATION);
    }
  }, [variations, variationId, qty]);

  const addToCart = (e: MouseEvent<HTMLButtonElement>) => {
    // Prevent the card from navigating.
    e.stopPropagation();
    e.preventDefault();

    if (!variationId) {
      return;
    }
    increase(product, variationId);
  };

  const selectedVariation = (e: MouseEvent<HTMLButtonElement>, vId: string) => {
    // Prevent the card from navigating.
    e.stopPropagation();
    e.preventDefault();

    setVariationId(vId);
  };

  const gridAlign: React.CSSProperties = {
    gridColumn: 1,
    gridRow: 1,
  };

  const link = useMemo(() => {
    const catId = product.categories[0].id;
    const cat = categories.find((c) => c.id === catId);
    if (!cat) {
      return "#";
    }

    return `/products${cat?.slug}/${product.slug}`;
  }, [categories, product]);

  return (
    <Card
      elevation={4}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 384,
        marginLeft: "auto",
        marginRight: "auto",
      }}
      className={className}
    >
      <Link to={link} style={{ color: "inherit" }}>
        <div style={{ display: "grid" }}>
          {/* Grid div allows overlapping the product image and the discount value. */}
          <DtwImage
            sx={{
              ...gridAlign,
              height: 384,
              width: 1,
              objectFit: "cover",
            }}
            picture={product.pictures[0]}
            alt={product.name}
          />
          {!!product.discountValue && (
            <Box
              sx={{
                ...gridAlign,
                display: "inline-flex",
                padding: "24px 16px",
                margin: "0 auto auto 0",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "0 50px 50px 50px",
                backgroundColor: (t) => t.palette.secondary.main,
              }}
            >
              <Typography
                variant="body1"
                style={{ color: secondaryBgTextColor }}
              >
                {product.discountValue}
              </Typography>
            </Box>
          )}
          {!disableInteraction && (
            <div style={{ marginTop: "auto", ...gridAlign }}>
              {/* The parent div is required to show the box at the bottom of the profile picture. */}
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ButtonGroup>
                  {product.variations?.map((v) => (
                    <Button
                      key={v.id}
                      variant={v.id == variationId ? "contained" : undefined}
                      onClick={(e) => selectedVariation(e, v.id)}
                    >
                      {v.name}
                    </Button>
                  ))}
                </ButtonGroup>
              </Box>
            </div>
          )}
        </div>
        {!disableInteraction && (
          <ClientOnly fallback={<div style={{ height: "40px" }} />}>
            {() => (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* Parent div helps keep the IconButton animation square instead of stretching on the while card. */}
                {!variationInCart && (
                  <Tooltip
                    title={
                      variationId
                        ? undefined
                        : "Select a type to add to cart first."
                    }
                  >
                    <IconButton
                      aria-label={`Add ${product.name} to cart`}
                      onClick={addToCart}
                    >
                      <ShoppingCartOutlined color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
                {!!variationId && variationInCart && (
                  <CartCounter product={product} variationId={variationId} />
                )}
              </div>
            )}
          </ClientOnly>
        )}
        <Box sx={{ p: (t) => t.spacing(1) }}>
          <Typography
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            width={1}
            display="inline-block"
            overflow="hidden"
            variant="caption"
          >
            {product.name}
          </Typography>

          <ProductPrice product={product} variationId={variationId} />
        </Box>
      </Link>
    </Card>
  );
}

export default styled(ProductCard)``;
